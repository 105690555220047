import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Aircraft } from '@shared/models/aircrafts.model';
import { ConfigEndpoint } from '@shared/services/config-endpoint-decorator';
import { SeasonCode } from '@shared/models/seasons.model';

@Injectable({ providedIn: 'root' })
@ConfigEndpoint({ version: 2 })
export class AircraftsHttpService {
  private apiUrl: string;

  constructor(private httpClient: HttpClient) {}

  getFleet(season: SeasonCode): Observable<Aircraft[]> {
    const now = Math.floor(Date.now() / 1000);
    return this.httpClient.get<Aircraft[]>(`${this.apiUrl}/${season}/fleet?time=${now}`);
  }

  addAircraft(season: SeasonCode, aircraft: Aircraft): Observable<Aircraft> {
    const modifiedAircraft = { ...aircraft, season: season };
    delete modifiedAircraft.id;
    return this.httpClient.post<Aircraft>(`${this.apiUrl}/${season}/fleet`, modifiedAircraft);
  }

  updateAircraft(season: SeasonCode, aircraft: Aircraft): Observable<Aircraft> {
    return this.httpClient.put<Aircraft>(`${this.apiUrl}/${season}/fleet/${aircraft.id}`, aircraft);
  }

  deleteAircraft(season: SeasonCode, id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/${season}/fleet/${id}`);
  }
}
